<template>
    <div id="app">
        <v-app id="inspire">
            <Navbar/>
            <Table/>
        </v-app>
    </div>
</template>

<script>

import Navbar from '../components/Navbar.vue';
import Table from '../components/Tables/TableLogs.vue';
export default {
    components:{
        Navbar,
        Table,
    }
}
</script>

<style scoped>

</style>