<template>
    <div id="app">
    <v-app id="inspire">
       <v-data-table        :headers="table.headers"
        :items="table.logsTable"
        item-key="id"
        class="elevation-1"
        :loading="table.loading"
        loading-text="Cargando..."
        dense
        :search="table.search"
      >
        
      </v-data-table>
    </v-app>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      table: (state) => state.TableLogs,
    }),
  },
  methods: {
    ...mapActions("TableLogs",["loadItems"])
  },
  mounted () {
   this.loadItems()
  },
}
</script>